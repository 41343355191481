import React from 'react';
import './batteryStoragePage.scss';
import { batteryStorageBenefits, batteryStorageList } from './mock';
import Button from 'react-bootstrap/Button';
import { NavLink } from 'react-router-dom';

const BatteryStoragePage = () => {
  return (
    <div className="batteryStoragePage">
      <div className="container">
        <h4 className=" fw-bold">Battery Storage</h4>
        <p className="page-description mb-4">
          Solar panel battery storage systems are a technology used to store the excess energy
          generated by solar panels for later use. Solar panels generate electricity from sunlight,
          but this electricity is not always used immediately. A solar panel battery storage system
          allows you to store this excess electricity and use it when the sun is not shining, such
          as during the night or on cloudy days. Here's how a solar panel battery storage system
          typically works:
        </p>
        <ul className="batteryStorageList mb-5">
          {batteryStorageList.map(item => {
            return (
              <li key={item.id}>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </li>
            );
          })}
        </ul>
        <h4 className="fw-bold mb-4">Benefits of Solar Panel Battery Storage</h4>
        <ul className="batteryStorageBenefitsList">
          {batteryStorageBenefits.map(item => {
            return (
              <li key={item.id}>
                <h4>{item.title}</h4>
                <p>{item.description}</p>
              </li>
            );
          })}
        </ul>
        <NavLink to="/get-started" className="d-flex mx-auto justify-content-center mt-5">
          <Button className={'get-started-btn'}>Get Started</Button>
        </NavLink>
      </div>
    </div>
  );
};

export default BatteryStoragePage;
