export const residentiallList = [
  {
    id: 1,
    title: 'Function:',
    description:
      'Solar panels contain solar cells made from semiconductor materials like silicon. When sunlight hits these cells, it excites electrons, generating an electric current. This direct current (DC) is then converted into alternating current (AC) using an inverter, which makes it compatible with the electrical systems of your home.',
  },
  {
    id: 2,
    title: 'Components:',
    description:
      'A typical residential solar panel system consists of several components, including solar panels, an inverter, mounting hardware, wiring, and a monitoring system.',
  },
  {
    id: 3,
    title: 'Installation:',
    description:
      'Solar panels can be installed on rooftops, walls, or ground-mounted systems, depending on available space and local regulations. The orientation and angle of installation are important to maximize energy production. South-facing rooftops with minimal shading tend to be ideal for solar panel installations.',
  },
  {
    id: 4,
    title: 'Net Metering:',
    description:
      'Many regions offer net metering or feed-in tariff programs. With net metering, any excess electricity generated by your solar panels is sent back to the grid, and you receive credits or payments for this surplus energy. This can further reduce your energy costs.',
  },
  {
    id: 5,
    title: 'Costs:',
    description:
      'The cost of residential solar panel installations has decreased over the years due to advancements in technology and increased adoption. The total cost depends on factors such as the size of the system, location, quality of components, and installation expenses.',
  },
  {
    id: 6,
    title: 'Incentives:',
    description:
      'Many governments, local authorities, and utilities offer incentives to promote the adoption of solar energy. These incentives may include tax credits, rebates, grants, and low-interest loans.\n',
  },
  {
    id: 7,
    title: 'Battery Storage:',
    description:
      "Some homeowners choose to install battery storage systems alongside solar panels. These batteries store excess energy generated during the day for use during the night or during times when the sun isn't shining.",
  },
  {
    id: 8,
    title: 'Warranty:',
    description:
      'California Solar Group gives a warranty of 25 years. These warranties typically cover panel performance, efficiency, and durability.',
  },
  {
    id: 9,
    title: 'Installation Considerations:',
    description:
      "Before installing solar panels, it's important to assess factors like the amount of sunlight your location receives, shading from trees or buildings, local regulations, and your electricity consumption patterns.",
  },
];

export const residentialBenefits = [
  {
    id: 1,
    title: 'Reduced Electricity Bills:',
    description:
      'Solar panels generate electricity from sunlight, which can offset or even eliminate your electricity bills.',
  },
  {
    id: 2,
    title: 'Environmentally Friendly:',
    description:
      'Solar energy is a clean and renewable source of energy that produces little to no greenhouse gas emissions.',
  },
  {
    id: 3,
    title: 'Low Maintenance:',
    description:
      'Solar panels have no moving parts and require minimal maintenance. Regular cleaning and occasional inspections are usually sufficient.',
  },
  {
    id: 4,
    title: 'Increased Home Value:',
    description:
      'Homes with solar panel installations often have higher resale values, as prospective buyers are attracted to energy-efficient features.',
  },
];
