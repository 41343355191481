export const batteryStorageList = [
  {
    id: 1,
    title: 'Solar Panels:',
    description:
      'Photovoltaic (PV) solar panels are installed on your roof or property. These panels convert sunlight into electricity.',
  },
  {
    id: 2,
    title: 'Inverter:',
    description:
      'The solar panels generate direct current (DC) electricity, which needs to be converted into alternating current (AC) electricity for household use. An inverter does this conversion.',
  },
  {
    id: 3,
    title: 'Battery Storage:',
    description:
      'The excess electricity generated by the solar panels is stored in batteries instead of being fed back into the grid. Batteries are designed to store this energy and release it when needed.',
  },
  {
    id: 4,
    title: 'Energy Management System:',
    description:
      'An energy management system controls the flow of electricity between the solar panels, batteries, and your household. It ensures that the right amount of electricity is stored and used efficiently.',
  },
  {
    id: 5,
    title: 'Usage and Grid Connection:',
    description:
      "During times when the solar panels produce more electricity than you're using, the excess energy is stored in the batteries. When the panels aren't generating enough electricity (e.g., at night), the stored energy from the batteries is used to power your home. If the batteries are depleted, you can still draw electricity from the grid.\n",
  },
];

export const batteryStorageBenefits = [
  {
    id: 1,
    title: 'Energy Independence:',
    description:
      'With battery storage, you can become less reliant on the grid and draw more of your energy from your own solar panels, reducing your energy bills.',
  },
  {
    id: 2,
    title: 'Backup Power:',
    description:
      'Solar battery systems can provide backup power during grid outages, keeping essential appliances and systems running.',
  },
  {
    id: 3,
    title: 'Time-of-Use Savings:',
    description:
      'In areas with time-of-use electricity pricing, you can store excess energy when rates are low and use it during peak hours when rates are higher, saving money.',
  },
  {
    id: 4,
    title: 'Environmental Impact:',
    description:
      'By storing excess solar energy, you reduce the need to rely on fossil fuels for electricity during periods of low sunlight, thus reducing your carbon footprint.',
  },
  {
    id: 5,
    title: 'Grid Support:',
    description:
      'In some cases, solar battery systems can help stabilize the grid by providing power during peak demand times, reducing strain on power plants.',
  },
];
